// Function to handle lazy loading of YouTube videos
function lazyLoadYouTubeVideo(video) {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const iframe = entry.target;
                    const src = iframe.getAttribute('data-src');
                    iframe.setAttribute('src', src);
                    observer.unobserve(iframe);
                }
            });
        },
        { rootMargin: '0px 0px 50px 0px' } // Adjust the root margin as needed
    );

    observer.observe(video);
}

// Get all video iframes with the 'lazy-load' class
const lazyLoadVideos = document.querySelectorAll('.lazy-load-youtube');

// Lazy load each video
lazyLoadVideos.forEach((video) => {
    lazyLoadYouTubeVideo(video);
});