import IMask from 'imask';

let quantitySwitch = document.querySelectorAll('.quantity');

quantitySwitch?.forEach(el => {
    let inputField = el.querySelector('input[type=number]');
    let increaseButton = el.querySelector('.plus');
    let decreaseButton = el.querySelector('.minus');

    function increaseValue() {
        let currentValue = inputField.value;

        currentValue++;
        inputField.value = currentValue;
    }

    function decreaseValue() {
        let currentValue = inputField.value;

        if (currentValue > 0) {
            currentValue--;
            inputField.value = currentValue;
        }
    }

    increaseButton.addEventListener('click', increaseValue);
    decreaseButton.addEventListener('click', decreaseValue);
});

let cartItem = document.querySelectorAll('.cart-items__list .item');

cartItem.forEach(el => {
    let deleteBtn = el.querySelector('.delete-item')

    deleteBtn.addEventListener('click', function () {
        el.remove();
    })
});

let btnOpenDescription = document.querySelector('.read-full');
let specialOfferDescription = document.querySelector('.special-offer__description p')

btnOpenDescription?.addEventListener('click', function(event){
    this.classList.add('hidden');
    specialOfferDescription.classList.add('active');
});

//mask phone
let phoneInput = document.getElementById('phone-input');

if(phoneInput) {
    const maskOptions = {
        mask: '+{38}(000)000-00-00',
        lazy: false
    };
    const mask = IMask(phoneInput, maskOptions);
}
//validation
let cartForm = document.getElementById('cart-form');
cartForm?.addEventListener('submit', function(event){
    let phoneMask = /^\+38\(\d{3}\)\d{3}-\d{2}-\d{2}$/;

    if(!phoneInput.value.match(phoneMask)) {
        event.preventDefault();
        phoneInput.closest('.input-wrap').classList.add('error');
    } else {
        phoneInput.closest('.input-wrap').classList.remove('error');
    }
});