let burgerMenuBtn = document.querySelectorAll('.burger-btn');
let closeMenu = document.getElementById('close-menu');
let menuContainer = document.getElementById('mob-menu');
// let openCategory = document.getElementById('open-category');
// let mobCategory = document.getElementById('mob-category');
let menuCategoryBtn = document.getElementById('category-menu-btn');

burgerMenuBtn.forEach(el => {
    el.addEventListener('click', function(event){
        menuContainer.classList.add('active');
        document.body.classList.add('no-scroll');
    });
});

menuCategoryBtn?.addEventListener('click', function(event){
    this.classList.toggle('active');
});

// openCategory?.addEventListener('click', function(event){
//     mobCategory.classList.toggle('active');
// });

closeMenu?.addEventListener('click', function(event){
    menuContainer.classList.remove('active');
    document.body.classList.remove('no-scroll');
    // mobCategory.classList.remove('active');
});

document.addEventListener('mouseup', function (event) {
    if (!menuCategoryBtn.contains(event.target)) {
        menuCategoryBtn.classList.remove('active');
    }

    if (!menuContainer.contains(event.target)) {
        menuContainer.classList.remove('active');
        document.body.classList.remove('no-scroll');
        // mobCategory.classList.remove('active');
    }
});