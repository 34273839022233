// Import Swiper and modules
import Swiper from 'swiper';
import {Pagination, Navigation, Autoplay, Thumbs} from "swiper/modules";

Swiper.use([Pagination, Navigation, Autoplay, Thumbs])

new Swiper('.hero__slider', {
    slidesPerView: 1,
    centeredSlides: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    navigation: {
        prevEl: ".hero-prev",
        nextEl: ".hero-next",
    },
    pagination: {
        el: ".hero__pagination",
        clickable: true,
    },
});

new Swiper('.about-us__slider', {
    slidesPerView: 1,
    navigation: {
        prevEl: ".about-us-prev",
        nextEl: ".about-us-next"
    },
    pagination: {
        el: ".about-us__slider .swiper-pagination",
        clickable: true,
    },
});

new Swiper('.product-slider', {
    slidesPerView: "auto",
    spaceBetween: 10,
    nested: true,
    breakpoints: {
        1025: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
        }
    },
    pagination: {
        el: ".product-slider .swiper-pagination",
        clickable: true,
    },
    navigation: {
        prevEl: '.popular-product .title-navigation__prev',
        nextEl: '.popular-product .title-navigation__next',
    },
});

let product = document.querySelectorAll('.product');

product.forEach(el => {
    new Swiper(el.querySelector('.product-img'), {
        slidesPerView: 1,
        spaceBetween: 5,
        navigation: {
            prevEl: el.querySelector(".product-img-prev"),
            nextEl: el.querySelector(".product-img-next")
        },
        pagination: {
            el: el.querySelector('.product-img .swiper-pagination'),
            clickable: true,
        },
    });
});

let thumbnailsSlider = new Swiper('.one-product__img-thumbnails', {
    slidesPerView: 5,
    spaceBetween: 10,
    watchSlidesProgress: true,
    navigation: {
        prevEl: '.img-thumbnails-prev',
        nextEl: '.img-thumbnails-next',
    },
});

let gallerySlider = new Swiper('.one-product__img-slider', {
    slidesPerView: 1,
    navigation: {
        prevEl: '.img-slider-prev',
        nextEl: '.img-slider-next',
    },
    thumbs: {
        swiper: thumbnailsSlider
    },
});

new Swiper('.why-chosen__img-slider', {
    slidesPerView: 1,
    breakpoints: {
        1025: {
            slidesPerView: 3,
            spaceBetween: 16
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 16
        }
    },
    navigation: {
        prevEl: ".why-chosen__img-slider .img-slider-prev",
        nextEl: ".why-chosen__img-slider .img-slider-next"
    },
    pagination: {
        el: ".why-chosen__img-slider .swiper-pagination",
        clickable: true,
    },
});

new Swiper('.reviews-slider', {
    slidesPerView: "auto",
    spaceBetween: 10,
    breakpoints: {
        1025: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
        }
    },
    pagination: {
        el: ".reviews-slider .swiper-pagination",
        clickable: true,
    },
    navigation: {
        prevEl: '.reviews .title-navigation__prev',
        nextEl: '.reviews .title-navigation__next',
    },
});