import './utils/modals';
import './utils/slider';
import './utils/tabs';
import './utils/accordion';
import './utils/menu';
import "./utils/cart"
import "./utils/lazy-load-video"
import Swiper from "swiper";

//description
let descriptionText = document.querySelector('.description__text .text-wrap');
let descriptionOpen = document.querySelector('.description__open');

if (window.matchMedia("(max-width: 767px)").matches && descriptionText?.offsetHeight > 300) {
    descriptionText.classList.add('hidden');
    descriptionOpen.classList.add('active');
}

descriptionOpen?.addEventListener('click', function(event){
    this.classList.remove('active');
    descriptionText.classList.remove('hidden');
});

//scroll top
let scrollTopButton = document.getElementById('scroll-top');

scrollTopButton?.addEventListener('click', function(event){
    window.scroll(0, 0);
});

function toggleScrollButton() {
    if (window.scrollY > 300) {
        scrollTopButton.style.display = "block";
    } else {
        scrollTopButton.style.display = "none";
    }
}

if (scrollTopButton) {
    window.addEventListener("scroll", toggleScrollButton);
}

//catalog
let categoryLabels = document.querySelectorAll('.categories-list .category-label');
let showAllCategories = document.querySelector('.categories-list .show-more');
let hiddenAllCategories = document.querySelector('.categories-list .hidden');

showSamCategory(categoryLabels, 6)

showAllCategories?.addEventListener('click', function(event){
    this.style.display = "none";
    hiddenAllCategories.style.display = "block";
    categoryLabels.forEach(function(el, index) {
        el.classList.add('visible');
    });
});

hiddenAllCategories?.addEventListener('click', function(event){
    this.style.display = "none";
    showAllCategories.style.display = "block";
    categoryLabels.forEach(function(el, index) {
        el.classList.remove('visible');
    });
    showSamCategory(categoryLabels, 6)
});

function showSamCategory(categories, numberCategories) {
    categories.forEach(function(el, index) {
        if (index <= numberCategories) {
            el.classList.add('visible');
        }
    });
}






